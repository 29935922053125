import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

import CartButtonWidget from '../CartButton/CartButton';

import { subscribeToDebouncedResizeChanging } from '../../observer/resizeObserver';
import { subscribeToDeviceChanging } from '../../observer/deviceObserver';

import {
  ACTIVE_CLASS,
  BURGER,
  BURGER_BOX,
  CART_CLASS,
  COLLAPSED_CLASS,
  FIXED,
  HEADER_LAYOUT_WRAPPER,
  HEADER_WRAPPER,
  HIDE_SCROLL_BODY_CLASS,
  BURGER_MENU_OPENED,
  LOGO_CLASS,
  MENU_WITH_CHILDREN,
  NAV,
  NAV_BOX_WRAP,
  SUB_MENU_BTN_CLASS,
  SUB_MENU_ITEM,
  SUB_MENU_LIST,
  SUB_MENU_OPEN_CLASS,
  SUB_MENU_NAV_HAS_SCROLL,
  SUB_MENU_SCROLL,
  TOP_POSITION_CLASS,
  WITH_BURGER_CLASS,
  WITHOUT_BURGER_CLASS,
} from './constants';
import browser from '../../helpers/browser';
import updateCssRootBorders from './utils/updateCssRootBorders';

class Header extends WidgetWrapper {
  init = () => {
    this.elHeaderWrapper = dom.getElement(this.selector);

    if (!this.elHeaderWrapper) return;

    this.elHeader = dom.getElement('.header', this.elHeaderWrapper);
    this.settings = JSON.parse(this.elHeaderWrapper.dataset.settings);
    this.layoutsSize = 0;
    this.logoWidth = 0;
    const { burger } = this.settings;

    updateCssRootBorders(this.elHeader);

    if (!burger) this.connectCollapse();

    this.elHeaderWrapperFixed = dom.getElement(FIXED, this.elHeaderWrapper);
    this.elBurgerBox = dom.getElement(BURGER_BOX, this.elHeaderWrapper);
    this.elBurger = dom.getElement(BURGER, this.elHeaderWrapper);
    this.elNav = dom.getElement(NAV, this.elHeaderWrapper);

    this.initSubMenuScroll();

    if (this.elHeaderWrapperFixed) {
      this.defaultOpacity = this.elHeaderWrapperFixed.style.opacity;
      this.connectControlOfScroll();
    }

    if (this.elBurger) this.connectControlBurgerMenu(this.elBurger, this.elNav);

    this.showHeader();
  };

  showHeader = () => {
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elHeader);

    if (!elHeaderWrapper) return;

    const elSWrapper = elHeaderWrapper.parentNode;

    if (!elSWrapper) return;

    dom.showOpacity(elSWrapper);
  }

  setIsTopOverflow = (
    mainHeader,
    headerFixed,
    defaultOpacity,
  ) => {
    dom.window.requestAnimationFrame(() => {
      dom.addClass(mainHeader, TOP_POSITION_CLASS);
      dom.updateStyle(headerFixed, { opacity: defaultOpacity });
    });
  };

  unsetIsTopOverflow = (mainHeader, headerFixed) => {
    dom.window.requestAnimationFrame(() => {
      dom.removeClass(mainHeader, TOP_POSITION_CLASS);
      dom.updateStyle(headerFixed, { opacity: 1 });
    });
  };

  connectControlOfScroll = () => {
    dom.on(dom.window, 'scroll', () => {
      if (dom.window.pageYOffset !== 0) {
        this.unsetIsTopOverflow(
          this.elHeaderWrapper,
          this.elHeaderWrapperFixed,
        );
      } else {
        this.setIsTopOverflow(
          this.elHeaderWrapper,
          this.elHeaderWrapperFixed,
          this.defaultOpacity,
        );
      }
    });
  };

  connectCollapse = async () => {
    this.elLayoutsWithoutLogo = dom.getCollection(`${HEADER_LAYOUT_WRAPPER}:not(.${LOGO_CLASS})`, this.elHeaderWrapper);

    if (isEmpty(this.elLayoutsWithoutLogo)
      || (this.elLayoutsWithoutLogo.length === 1
        && dom.hasClass(this.elLayoutsWithoutLogo[0], CART_CLASS))) return;

    for (let i = 0; i <= this.elLayoutsWithoutLogo.length; i += 1) {
      if (this.elLayoutsWithoutLogo[i]) {
        const nav = dom.getElement('.nav__box', this.elLayoutsWithoutLogo[i]);
        // in IE container doesn`t stretch, so calculated child inner width
        const navForIE = dom.getElement('.s-wrapper_nav', nav);

        if (nav) {
          const navWidth = !browser.isIe()
            ? dom.getElementWidth(nav)
            : navForIE.clientWidth;

          this.layoutsSize += navWidth;
        } else {
          this.layoutsSize += dom.getElementWidth(this.elLayoutsWithoutLogo[i]);
        }
      }
    }

    // This logic for IE
    // ToDO: IE
    if (browser.isIe()) await this.addLogoWidthInIe();

    this.changeSize();

    this.elCloneWithoutBurger = dom.getElement(
      HEADER_WRAPPER,
      this.elHeaderWrapper,
    ).cloneNode(true);

    this.collapse();
    subscribeToDeviceChanging(this.elHeaderWrapper, this.changeSize);
    subscribeToDebouncedResizeChanging(this.elHeaderWrapper, this.collapse);
  };

  initSubMenuScroll = () => {
    const removeScroll = (scrollWrapper) => {
      dom.updateStyle(scrollWrapper, {
        overflowY: null,
        width: null,
        height: null,
      });
    };

    let currentScrollWrapper = null;
    const resetScroll = () => {
      if (!currentScrollWrapper) return;

      currentScrollWrapper.scrollTop = 0;
    };

    const init = () => {
      const subMenus = dom.getCollection(SUB_MENU_LIST, this.elHeader);

      if (!subMenus.length) return;

      subMenus.forEach((subMenuWrapper) => {
        const scrollWrapper = dom.getElement(SUB_MENU_SCROLL, subMenuWrapper);

        if (!scrollWrapper) return;

        currentScrollWrapper = scrollWrapper;

        dom.updateStyle(subMenuWrapper, { height: null });
        const parent = subMenuWrapper.closest(MENU_WITH_CHILDREN);
        dom.off(parent, 'mouseleave', resetScroll);

        if (this.isCollapsed) {
          if (dom.hasClass(parent, SUB_MENU_OPEN_CLASS)) {
            dom.removeClass(parent, SUB_MENU_OPEN_CLASS);
          }

          dom.removeClass(subMenuWrapper, SUB_MENU_NAV_HAS_SCROLL);
          removeScroll(scrollWrapper);
          return;
        }

        dom.on(parent, 'mouseleave', resetScroll);

        const bottomOffset = 20 + (browser.isIe() ? 20 : 0);
        const { height } = scrollWrapper.getBoundingClientRect();
        const { top } = subMenuWrapper.getBoundingClientRect();

        if (top + height + bottomOffset <= dom.window.innerHeight) {
          dom.removeClass(subMenuWrapper, SUB_MENU_NAV_HAS_SCROLL);
          removeScroll(scrollWrapper);
          return;
        }

        dom.updateStyle(scrollWrapper, {
          overflowY: 'scroll',
          width: '100%',
          height: '100%',
        });

        const subMenuHeight = dom.window.innerHeight - top - bottomOffset;
        dom.addClass(subMenuWrapper, SUB_MENU_NAV_HAS_SCROLL);
        dom.updateStyle(subMenuWrapper, { height: `${subMenuHeight}px` });
      });
    };
    init();

    subscribeToDebouncedResizeChanging(dom.document.body, init, 300);
  };

  changeSize = () => {
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elHeaderWrapper);
    this.elHeaderLogo = dom.getElement(`.${LOGO_CLASS}`, elHeaderWrapper);
    const elLogoImg = dom.getElement('img', this.elHeaderLogo);

    if (!elLogoImg) return;

    const elLogoWrap = dom.getElement('.crop', this.elHeaderLogo);
    const elLogoCrop = dom.getElement('.mode_cover', this.elHeaderLogo);

    // This logic for IE
    // ToDO: IE
    const { offsetHeight: height } = elLogoWrap;
    const {
      naturalWidth,
      naturalHeight,
      offsetHeight: heightForIE,
      offsetWidth: widthForIE,
    } = elLogoImg;

    let width = !browser.isIe()
      ? Math.floor((naturalWidth / naturalHeight) * height)
      : Math.floor((widthForIE / heightForIE) * height);

    if (elLogoCrop) {
      const elLogoCropContent = dom.getElement('.crop__content', this.elHeaderLogo);
      const { offsetWidth: maxWidth } = elLogoCropContent;
      width = maxWidth;
    }

    if (width >= 360) width = 360;
    if (elLogoWrap) dom.updateStyle(elLogoWrap, { width: `${width}px` });
    if (this.logoWidth === width) return;

    this.layoutsSize -= this.logoWidth;
    this.logoWidth = width;
    this.layoutsSize += width;
  };

  addLogoWidthInIe = () => new Promise((resolved) => {
    const elHeaderLogo = dom.getElement(`.${LOGO_CLASS}`, this.elHeader);
    const elLogoWrap = dom.getElement('.crop', this.elHeaderLogo);
    const elLogoImg = dom.getElement('img', elHeaderLogo);

    const image = new Image();

    image.onload = () => {
      image.style.opacity = '0';
      image.style.position = 'fixed';
      document.body.appendChild(image);
      const { offsetWidth: widthForIE, offsetHeight: heightForIE } = image;
      const { offsetHeight: height } = elLogoWrap;

      const width = Math.floor((widthForIE / heightForIE) * height);
      dom.updateStyle(elLogoWrap, { width: `${width}px` });

      document.body.removeChild(image);
      resolved();
    };

    image.src = elLogoImg.src;
  })

  updateCartButton = () => {
    const elHeaderCart = dom.getElement(`.${CART_CLASS}`, this.elHeaderWrapper);

    if (elHeaderCart) CartButtonWidget.refreshCartButtonWidget(this.elHeaderWrapper);
  };

  collapse = () => {
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elHeaderWrapper);
    const isCollapsed = dom.hasClass(this.elHeader, COLLAPSED_CLASS);
    const headerWrapperWidth = dom.getElementWidth(elHeaderWrapper) - 30;

    if (this.layoutsSize > headerWrapperWidth) {
      if (isCollapsed) return;

      dom.addClass(this.elHeader, COLLAPSED_CLASS);
      this.isCollapsed = true;

      const elWrapper = dom.getElement(`.${WITHOUT_BURGER_CLASS}`, this.elHeaderWrapper);

      if (!elWrapper) return;

      dom.removeClass(elWrapper, WITHOUT_BURGER_CLASS);
      dom.addClass(elWrapper, WITH_BURGER_CLASS);

      if (this.elCloneWithBurger) {
        elWrapper.replaceChild(this.elCloneWithBurger, elHeaderWrapper);
        this.elBurger = dom.getElement(BURGER, this.elHeaderWrapper);
        this.elBurgerBox = dom.getElement(BURGER_BOX, this.elHeaderWrapper);
        this.elNav = dom.getElement(NAV, this.elHeaderWrapper);
        this.connectControlBurgerMenu(this.elBurger, this.elNav);
        this.updateCartButton();
        return;
      }

      this.setCloneWithBurger(elHeaderWrapper);
    } else {
      if (!isCollapsed) return;

      dom.removeClass(this.elHeader, COLLAPSED_CLASS);
      this.isCollapsed = false;

      const elWrapper = dom.getElement(`.${WITH_BURGER_CLASS}`, this.elHeaderWrapper);

      if (!elWrapper) return;

      dom.removeClass(elWrapper, WITH_BURGER_CLASS);
      dom.addClass(elWrapper, WITHOUT_BURGER_CLASS);
      elWrapper.replaceChild(this.elCloneWithoutBurger, elHeaderWrapper);
      this.updateCartButton();
    }
  };

  setCloneWithBurger = (headerWrapper) => {
    const elBurgerBox = dom.getElement(BURGER_BOX);
    const elHeaderNav = dom.getElement('.header-nav', this.elHeaderWrapper);
    const elHeaderCart = dom.getElement(`.${CART_CLASS}`, this.elHeaderWrapper);
    const elPrevBurgerClassList = get(elBurgerBox, 'previousSibling.classList', null);
    const isCartBeforeBurger = elPrevBurgerClassList && elPrevBurgerClassList.contains(CART_CLASS);
    const isLogoBeforeBurger = elPrevBurgerClassList && elPrevBurgerClassList.contains(LOGO_CLASS);
    let elForInsert;
    let headerCenter = false;

    elBurgerBox.removeAttribute('style');

    if (elHeaderNav) {
      const right = dom.hasClass(elHeaderNav, '_right');
      const left = dom.hasClass(elHeaderNav, '_left');
      const center = dom.hasClass(elHeaderNav, '_center');

      if (right) {
        dom.addClass(elBurgerBox, 'right');
      } else if (left) {
        dom.addClass(elBurgerBox, 'left');
      } else if (center) {
        headerCenter = true;
      }

      const elSubNav = dom.getCollection('.nav__item_has-child', elHeaderNav);
      const elForRemoveStyle = dom.getCollection('.nav__item .nav__item-inner, .nav__item .nav__link', elHeaderNav);

      elForRemoveStyle.forEach((el) => {
        el.removeAttribute('style');
      });

      if (elSubNav) {
        const elButton = dom.createElement('button');
        elButton.type = 'button';
        elButton.tabIndex = -1;
        dom.addClass(elButton, 'btn');
        dom.addClass(elButton, 'sub-menu__toggle');

        elSubNav.forEach((el) => {
          const button = elButton.cloneNode(true);
          const elChild = dom.getElement('.nav__item-inner', el);
          elChild.appendChild(button);
        });
      }

      dom.removeClass(elHeaderNav, ['_right', '_left', '_center']);
    }

    if (this.elHeaderLogo) {
      let isLogoFirst = !isLogoBeforeBurger;

      if (elHeaderCart && !isLogoBeforeBurger && isCartBeforeBurger) {
        const isLogoBeforeCart = dom.hasClass(elHeaderCart.previousSibling, LOGO_CLASS);

        isLogoFirst = !isLogoBeforeCart;
      }

      dom.removeClass(this.elHeaderLogo, ['_right', '_left', '_center']);

      const logoAlighment = this.settings.orientation
        || (isLogoFirst ? 'left' : 'right');

      dom.addClass(
        this.elHeaderLogo,
        `_${logoAlighment}`,
      );

      if (headerCenter || !elHeaderNav) dom.addClass(elBurgerBox, !isLogoFirst ? 'left' : 'right');
      if (!isLogoFirst) elForInsert = this.elHeaderLogo;
    }

    if (elHeaderCart) {
      let isCartFirst = !isCartBeforeBurger;

      if (this.elHeaderLogo && !isCartBeforeBurger && isLogoBeforeBurger) {
        const isCartBeforeLogo = dom.hasClass(this.elHeaderLogo.previousSibling, CART_CLASS);

        isCartFirst = !isCartBeforeLogo;
      }

      if (!this.elHeaderLogo) {
        const logoAlighment = this.settings.orientation
          || (isCartFirst ? 'left' : 'right');

        dom.removeClass(elHeaderCart, ['_right', '_left', '_center']);
        dom.addClass(
          elHeaderCart,
          `_${logoAlighment}`,
        );
      }

      const prevClassList = get(elHeaderCart, 'previousSibling.classList', false);
      const isLogoPrev = prevClassList ? prevClassList.contains(LOGO_CLASS) : prevClassList;

      if (!isCartFirst && !isLogoPrev) elForInsert = elHeaderCart;
    }

    if (!elHeaderNav && !this.elHeaderLogo && !elHeaderCart && this.elLayoutsWithoutLogo[0]) {
      const className = this.elLayoutsWithoutLogo[0].classList;

      dom.addClass(elBurgerBox, className[1]);
    }

    if (elForInsert) headerWrapper.insertBefore(elBurgerBox, elForInsert);

    const elLayoutsWrapper = dom.getElement(NAV_BOX_WRAP, elBurgerBox);
    this.elLayoutsWithoutLogo.forEach((el) => {
      if (!dom.hasClass(el, CART_CLASS)) elLayoutsWrapper.appendChild(el);
    });
    this.elCloneWithBurger = dom.getElement(HEADER_WRAPPER, this.elHeaderWrapper).cloneNode(true);
  };

  connectControlBurgerMenu = (burger, nav) => {
    if (burger) dom.on(burger, 'click', this.toggleBurgerMenu);
    if (nav) dom.on(nav, 'click', this.toggleBurgerSubMenu);
  };

  toggleBurgerMenu = () => {
    if (dom.hasClass(this.elBurgerBox, ACTIVE_CLASS)) {
      dom.removeClass(dom.document.body, HIDE_SCROLL_BODY_CLASS);
      dom.removeClass(dom.document.body, BURGER_MENU_OPENED);
    } else {
      dom.addClass(dom.document.body, HIDE_SCROLL_BODY_CLASS);
      dom.addClass(dom.document.body, BURGER_MENU_OPENED);
    }

    dom.toggleClass(this.elBurgerBox, ACTIVE_CLASS);
  };

  toggleBurgerSubMenu = (e) => {
    const { target } = e;

    if (!dom.hasClass(target, SUB_MENU_BTN_CLASS)) return;

    e.preventDefault();
    const navItem = target.parentElement.parentElement;
    const elSubMenu = dom.getElement(SUB_MENU_LIST, navItem);
    const isOpen = dom.hasClass(navItem, SUB_MENU_OPEN_CLASS);

    if (isOpen) {
      dom.updateStyle(elSubMenu, { height: '0px' });
    } else {
      const elSubMenuItems = dom.getCollection(SUB_MENU_ITEM, elSubMenu);
      const updatedHeight = elSubMenuItems.length * (elSubMenuItems[0].clientHeight + 4);
      dom.updateStyle(elSubMenu, { height: `${updatedHeight}px` });
    }

    dom.toggleClass(navItem, SUB_MENU_OPEN_CLASS);
  };
}

export default Header;
